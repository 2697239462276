<template>
  <div class="relative">
    <!-- Content-->
    <div
      class="max-w-full lg:max-w-[960px] mx-auto px-4 lg:px-10 py-3 border-b border-gray-200 flex flex-col items-center"
    >
      <MiscAlert
        :visible="showAlert"
        :duration="3"
        type="info"
        :message="$t('index.alertMessage')"
        @update:visible="showAlert = $event"
      />
      <!-- Hero Section -->
      <HeroSection />

      <LayoutSpacer />

      <div class="flex flex-col items-center">
        <div class="w-full lg:w-[960px] flex justify-center px-4 lg:px-0">
          <!-- Ensures alignment and centering at 960px width -->
          <div class="w-full lg:w-[928px] flex flex-col items-start gap-6">
            <!-- Content specifically centered and aligned to 928px -->
            <h2 class="text-neutral-900 text-3xl lg:text-4xl font-bold">
              {{ $t('index.whyPetOwnersLove.title') }}
            </h2>
            <p class="text-neutral-900 text-base font-normal leading-normal">
              {{ $t('index.whyPetOwnersLove.description') }}
            </p>
          </div>
        </div>
      </div>

      <LayoutSpacer />

      <div class="w-full lg:w-[960px] flex justify-center px-4 lg:px-0">
        <!-- Content Container: Maintains specific width (928px) and aligns content to the start -->
        <div class="w-full lg:w-[928px] flex flex-col items-start gap-6">
          <h2
            class="text-neutral-900 text-2xl lg:text-[22px] font-bold leading-7 text-left"
          >
            {{ $t('index.howItWorks.title') }}
          </h2>
        </div>
      </div>

      <div class="flex flex-col items-center">
        <div class="w-full lg:w-[960px] flex justify-center px-4 lg:px-0">
          <!-- Ensures alignment and centering at 960px width -->
          <div class="w-full lg:w-[928px] flex flex-col items-start gap-6">
            <!-- Content specifically centered and aligned to 928px -->
            <div class="flex justify-center gap-3 py-5 px-4 flex-wrap">
              <!-- Box 1 -->
              <div
                class="flex flex-col items-start gap-3 p-4 bg-white rounded-lg border border-zinc-200 w-full lg:w-[calc(20%-12px)] hover:border-primary"
              >
                <NuxtLink to="/signup" class="w-full">
                  <img src="public/images/signup.svg" alt="Sign up" />
                  <h3 class="text-neutral-900 text-base font-bold mt-2">
                    {{ $t('index.howItWorks.steps.signup.title') }}
                  </h3>
                  <p class="text-slate-500 text-sm">
                    {{ $t('index.howItWorks.steps.signup.description') }}
                  </p>
                </NuxtLink>
              </div>

              <!-- Box 2 -->
              <div
                class="flex flex-col items-start gap-3 p-4 bg-white rounded-lg border border-zinc-200 w-full lg:w-[calc(20%-12px)] hover:border-primary"
              >
                <NuxtLink to="/veterinarians" class="w-full">
                  <img src="public/images/appointment.svg" alt="Sign up" />
                  <h3 class="text-neutral-900 text-base font-bold mt-2">
                    {{ $t('index.howItWorks.steps.bookMeeting.title') }}
                  </h3>
                  <p class="text-slate-500 text-sm">
                    {{ $t('index.howItWorks.steps.bookMeeting.description') }}
                  </p>
                </NuxtLink>
              </div>
              <!-- Box 3 -->
              <div
                class="flex flex-col items-start gap-3 p-4 bg-white rounded-lg border border-zinc-200 w-full lg:w-[calc(20%-12px)] hover:border-primary"
              >
                <NuxtLink to="/signup" class="w-full">
                  <img src="public/images/payment.svg" alt="Sign up" />
                  <h3 class="text-neutral-900 text-base font-bold mt-2">
                    {{ $t('index.howItWorks.steps.addPayment.title') }}
                  </h3>
                  <p class="text-slate-500 text-sm">
                    {{ $t('index.howItWorks.steps.addPayment.description') }}
                  </p>
                </NuxtLink>
              </div>
              <!-- Box 4 -->
              <div
                class="flex flex-col items-start gap-3 p-4 bg-white rounded-lg border border-zinc-200 w-full lg:w-[calc(20%-12px)] hover:border-primary"
              >
                <NuxtLink to="/signup" class="w-full">
                  <img src="public/images/talktovet.svg" alt="Sign up" />
                  <h3 class="text-neutral-900 text-base font-bold mt-2">
                    {{ $t('index.howItWorks.steps.talkToVet.title') }}
                  </h3>
                  <p class="text-slate-500 text-sm">
                    {{ $t('index.howItWorks.steps.talkToVet.description') }}
                  </p>
                </NuxtLink>
              </div>
              <!-- Box 5 -->
              <div
                class="flex flex-col items-start gap-3 p-4 bg-white rounded-lg border border-zinc-200 w-full lg:w-[calc(20%-12px)] hover:border-primary"
              >
                <NuxtLink to="/signup" class="w-full">
                  <img src="public/images/followup.svg" alt="Sign up" />
                  <h3 class="text-neutral-900 text-base font-bold mt-2">
                    {{ $t('index.howItWorks.steps.followUp.title') }}
                  </h3>
                  <p class="text-slate-500 text-sm">
                    {{ $t('index.howItWorks.steps.followUp.description') }}
                  </p>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LayoutSpacer />

      <div class="w-full lg:w-[960px] flex justify-center px-4 lg:px-0">
        <div class="w-full lg:w-[928px] flex flex-col items-start gap-6">
          <h2
            class="text-neutral-900 text-2xl lg:text-[22px] font-bold leading-7 text-left"
          >
            {{ $t('index.meetOurVets.title') }}
          </h2>
        </div>
      </div>

      <LayoutSpacer />

      <VetListItem
        v-for="vet in vetProfiles"
        :key="vet.id"
        :vet-profile="vet"
        :cta-text="$t('index.meetOurVets.ctaText')"
        truncate-length="180"
        @click="onBtnGoToVetProfile(vet.id)"
      >
        />

        <LayoutSpacer />

        <VideoCall />
      </VetListItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useVetStore } from '@/stores/vet'
import { useRouter } from 'vue-router'

const vetStore = useVetStore()
const showAlert = ref(false)
const router = useRouter()

// Getting the vet profiles, using SSR if available
const { data: vetProfiles } = useAsyncData('vetProfile', () =>
  vetStore.getAll(),
)

const onBtnGoToVetProfile = (id) => {
  router.push(`/veterinarians/${id}/book-meeting`)
}
</script>
