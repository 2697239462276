<template>
  <div
    class="w-full lg:w-[960px] h-[480px] overflow-hidden p-4 flex flex-col items-start"
  >
    <div
      class="pl-4 pt-4 bg-gradient-to-r from-black to-black rounded-xl flex flex-col justify-start h-full w-full"
      style="
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          url('/images/brand/petcall-hero.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
      "
    >
      <div class="px-10 pb-10 flex flex-col justify-start h-full">
        <!-- Text and button grouped together, moving as a unit -->

        <!-- Spacer div to push content to top if there's extra space -->
        <div class="flex-grow" />
        <h1
          class="text-white font-bold leading-[40px] sm:w-3/4 mb-5 sm:mb-6 text-3xl sm:text-4xl md:text-5xl md:leading-none"
          style="text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3)"
        >
          {{ $t('heroSection.title') }}
        </h1>
        <div class="flex justify-between items-center w-full">
          <NuxtLink
            to="/veterinarians"
            class="py-2 px-5 bg-primary rounded-xl flex justify-center items-center shadow-xl drop-shadow-xl gap-2 hover:bg-primary-light"
          >
            <span
              class="text-white text-base font-bold leading-normal tracking-tight"
            >
              {{ $t('heroSection.startVideoCall') }}
            </span>
          </NuxtLink>
          <div
            class="hidden sm:flex flex items-center justify-center py-2 px-5 bg-gray-800 bg-opacity-70 rounded-xl shadow-xl drop-shadow-xl hover:bg-gray-900"
            style="text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3)"
          >
            <PhoneIcon aria-hidden="true" class="w-4 h-4 mr-2 text-white" />
            <span class="text-white text-sm font-normal">
              {{ $t('heroSection.availability') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PhoneIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
